<template>
  <v-form v-model="valid" ref="form">
    <v-container>
      <p>date: {{displayDate}}</p>
        <v-row>
          <v-col cols="12" sm="9"><h1>{{licenseEdited.eier}}</h1></v-col>
          <v-col cols="12" sm="3">
            <v-switch
              v-model="edit"
              :label="`Redigering: ${edit ? 'på' : 'av'}`"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <v-text-field
              v-model="licenseEdited.eier"
              :rules="eierRules"
              :counter="40"
              label="Skole/institusjonsnavn"
              required
              :disabled="!edit"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="displayDate"
                  label="Date"
                  hint="Gyldig fra dato"
                  persistent-hint
                  prepend-icon="mdi-calendar-month-outline"
                  v-on="on"
                ></v-text-field>
              </template>
              <!-- @blur="date = parseDate(dateFormatted)" -->
              <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
            </v-menu>
          </v-col>

    <!-- @blur="date = parseDate(dateFormatted)" -->
          <v-col cols="12" md="4" sm="6">
            <v-select
              v-model="licenseEdited.institusjonsType"
              :items="items"
              :rules="[v => !!v || 'Institusjonstype må velges']"
              label="Institusjonstype"
              required
              :disabled="!edit"
            ></v-select>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <v-text-field
              v-model="licenseEdited.ressursnummer"
              :counter="20"
              label="Ressursnummer"
              required
              :disabled="!edit"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <v-text-field
              v-model="licenseEdited.kontaktperson"
              :rules="kontaktpersonRules"
              label="kontaktpersons fulle navn"
              required
              :disabled="!edit"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <v-text-field
              v-model="licenseEdited.kontaktpersonEpost"
              :rules="emailRules"
              label="kontaktpersons epostadresse"
              required
              :disabled="!edit"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <v-text-field v-model="licenseEdited.adresse" label="Adresse" required :disabled="!edit"></v-text-field>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <v-text-field v-model="licenseEdited.postnummer" label="Postnummer" :rules="postnummerRules" required :disabled="!edit"></v-text-field>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <v-text-field v-model="licenseEdited.sted" label="Sted" required :disabled="!edit"></v-text-field>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <v-text-field
              v-model="licenseEdited.brukerantall"
              label="Antall brukere"
              :rules="brukerantallRules"
              required
              :disabled="!edit"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <v-switch
              v-model="licenseEdited.aktiv"
              :label="`Aktiv ${licenseEdited.aktiv ? 'Ja' : 'Nei'}`"
              color="success"
              hide-details
              :disabled="!edit"
            ></v-switch>
          </v-col>
          <!-- <v-col cols="12" md="4" sm="6">
            <v-switch
              v-model="licenseEdited.betalt"
              :label="`Betalt ${licenseEdited.betalt ? 'Ja' : 'Nei'}`"
              color="success"
              hide-details
              :disabled="!edit"
            ></v-switch>
          </v-col> -->
          <v-col cols="12" md="4" sm="6">
            <v-text-field v-model="licenseEdited.id" disabled label="Skolekode"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="2">
            <v-btn :disabled="!edit" :loading="saving" color="success" class="mr-4" @click="updateLicense">Lagre</v-btn>
          </v-col>
        </v-row>

      <v-dialog
        v-model="dialog"
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">Oppdatering av lisensen gikk galt</v-card-title>

          <v-card-text>
            Det har oppstått en feil som forhindret å opprette en lisens. Ta en kikk på feilmeldingen nedenfor for mulig å finne årsaken.
            <code>{{ feilmelding.errors ? feilmelding.errors[0].errorType : feilmelding }}</code>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        top
      >
        Lisensen ble lagret
        <v-btn
          color="green"
          text
          @click="snackbar = false"
        >
          Yeay! :-)
        </v-btn>
      </v-snackbar>
    </v-container>

  </v-form>

</template>

<script>

import { updateLicense } from "@/graphql/mutations";
import * as queries from "@/graphql/queries";
import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";

export const listLicenses = `
  query ListLicenses(
    $filter: ModelLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
    }
  }
`;

export default {
  data() {
    return {
      license: {},
      licenseEdited: {},
      edit: false,
      saving: false,
      dialog: false,
      feilmelding: true,
      skolekoder: [],
      valid: true,
      snackbar: false,
      timeout: 2000,
      date: null, //new Date().toISOString().substr(0, 10),
      dateFormatted: null,
      // date: new Date('2019-11-11').toISOString().substr(0, 10), //'',
      // dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      menu1: false,
      // The rest
      eier: "",
      eierRules: [
        v => !!v || "Navn påkrevd",
        v => (v && v.length >= 3) || "Navn må ha minst tre bokstaver"
      ],
      ressursnummer: "",

      institusjonsType: null,
      items: ["ungdomsskole", "vgs", "annen"],

      kontaktperson: "",

      kontaktpersonRules: [
        v => !!v || "Navn påkrevd",
        v => (v && v.length > 3) || "Navnet må være lengre enn tre bokstaver"
      ],

      kontaktpersonsEpost: "",
      emailRules: [
        v => !!v || "Epost er påkrevet",
        v => /.+@.+\..+/.test(v) || "E-postadresse må være gyldig"
      ],

      adresse: "",

      postnummer: "",
      postnummerRules: [
        v => !!v || "Postnummer er påkrevet",
        v => /^[0-9]{4}$/.test(v) || "Postnummer må ha 4 siffer"
      ],
      sted: "",

      brukerantall: "", // Int
      brukerantallRules: [
        v => !!v || "Antall er påkrevet",
        v => /^[0-9]*$/.test(v) || "Må være et tall",
        v => v > 0 || "Må være større enn 0"
      ],

      skolekode: "",
      skolekodeRules: [
        v => !!v || "Skolekode er påkrevd",
        v => (v && v.length >= 5 && v.length <= 8) || "Skolekode må bestå av mellom 6 og 8 bokstaver",
        v => (this.skolekodeExists (v)) || "Skolekoden finnes fra før"
      ],

      // betalt: false // Boolean
    };
  },
  created () {
    this.getSkolekoder()
    this.getLicense()
    // console.log('dateFormatted', this.dateFormatted)
  },
  watch: {
    // call again the method if the route changes
    '$route': 'getLicense',
      // date(val) {
      //   this.dateFormatted = this.formatDate(this.date);
      // }
  },
  computed: {
    edited () {
      console.log('edited')
      return !(JSON.stringify(this.license) === JSON.stringify(this.licenseEdited))
    },
    displayDate () {
      return this.date
    }
  },
  methods: {
    skolekodeExists (code) {
      // console.log('this.skolekoder.indexOf(code)', this.skolekoder.indexOf(code))
      return this.skolekoder.indexOf(code) > -1 ? false : true
    },
    async getLicense () {
      //console.log('skolekode', this.$route.params.id)
      let result
      try {
        result = await API.graphql({
                  query: queries.getLicense,
                  variables: {
                    id: this.$route.params.id
                  },
                  // authMode: "API_KEY"
        })
        this.license = result.data.getLicense // .getLicense
        this.licenseEdited = Object.assign({}, this.license)
        this.dateFormatted = this.shortIsoDate(this.license.gyldigFraDato)
        this.date = new Date(this.license.gyldigFraDato).toISOString().substr(0, 10)
        // console.log(JSON.stringify('this.licenseEdited', this.licenseEdited))
        // this.licenseEdited.gyldigFraDato = date
        // console.log('DATE', date)
      } catch (err) {
        this.saving = false
        this.feilmelding = err // err.errors[0].message
        console.log("feil:", err.errors);
        this.dialog = true
      }
    },
    async getSkolekoder () {
      let result
      try {
        result = await API.graphql({
          query: listLicenses,
          variables: {
            limit: 2000
          },
          // authMode: "API_KEY"
        })
      } catch (err) {
         (err)
      }

      this.skolekoder = result.data.listLicenses.items.map( obj => {
        return obj.id
      })
      // console.log(this.skolekoder)
    },
    async updateLicense () {
      this.saving = true
      this.error = ''
      let result;
      console.log('this.licenseEdited', this.licenseEdited)
      try {
        if(!this.valid) {
          throw 'Skjemaet har feil utfylte felt'
        }
        result = await API.graphql({
          query: updateLicense,
          variables: {
            input: {
              id: this.license.id,
              eier: this.licenseEdited.eier,
              gyldigFraDato: this.displayDate, //Date.now(),
              ressursnummer: this.licenseEdited.ressursnummer,
              institusjonsType: this.licenseEdited.institusjonsType,
              kontaktperson: this.licenseEdited.kontaktperson,
              kontaktpersonEpost: this.licenseEdited.kontaktpersonsEpost,
              adresse: this.licenseEdited.adresse,
              postnummer: this.licenseEdited.postnummer,
              sted: this.licenseEdited.sted,
              brukerantall: Number(this.licenseEdited.brukerantall),
              aktiv: this.licenseEdited.aktiv,
              betalt: this.licenseEdited.betalt
            }
          },
        })
        console.log('this.licenseEdited.adresse', this.licenseEdited.adresse)
        console.log('result', result)
        this.reset
        this.saving = false
        this.edit = false
        this.snackbar = true

        this.$emit('updateLicense', this.license.id) // check in parent
      } catch (err) {
        this.saving = false
        this.feilmelding = err // err.errors[0].message
        console.log("feil:", err.errors);
        this.dialog = true
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    shortIsoDate(date) {
      if (!date) return null;
        date = date.substr(0, 10)
        const [year, month, day] = date.split("-");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  }
};
</script>

<style>
</style>
