<template>
  <div class="scroll-list">
    <LicenseEdit v-on:updateLicense="refreshLicenseList"/>
  </div>
</template>

<script>

import LicenseEdit from '@/components/licenses/LicenseEdit'

export default {
  components: {
    LicenseEdit
  },
  methods: {
    refreshLicenseList () {
      console.log('refreshing licenses ? - not yet..')
    }
  }
}
</script>

<style>

</style>
